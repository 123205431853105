import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder } from '@angular/forms';
import { AddressInformationFormGroupType } from 'src/app/models/utils/forms/address-information-form';

@Component({
    selector: 'app-address-step',
    templateUrl: './address-step.component.html',
    styleUrl: './address-step.component.scss',
})
export class AddressStepComponent implements OnInit {
    public parentForm: AddressInformationFormGroupType;

    constructor(
        private fb: FormBuilder,
        private controlContainer: ControlContainer
    ) {}

    ngOnInit(): void {
        this.parentForm = this.controlContainer.control as AddressInformationFormGroupType;
    }
}
