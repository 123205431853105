import { Injectable } from '@angular/core';
import { Customer, DynamicField, DynamicSections } from '@cargos/sprintpay-models';
import {
    CanCreateApprovalFlow,
    FirstLocation,
    PaymentReference,
    PaymentReferenceLookup,
} from '@cargos/sprintpay-models/dist/src/customer/customer';
import { BehaviorSubject, Observable, distinctUntilChanged, map } from 'rxjs';
import { User } from '../models/customer';
import {
    getCompanyCustomerReferenceMethod,
    getPaymentCustomerReferenceMethod,
    getPaymentDetailsMethod,
    getRequestDataMethod,
    getStandardRequestDataMethod,
} from '../models/payment/payment';
import { OptionType } from '../models/ui/dynamic-field-common.model';
import { CustomerService } from './utils/user/customer-handler.service';

export interface RangeAmount {
    name: string;
    value: number;
    message?: string;
}
@Injectable({
    providedIn: 'root',
})
export class HandlerdFeaturesService {
    private user = new BehaviorSubject<User>(User.fromJson({}));
    private location: FirstLocation;
    private locations = new BehaviorSubject<FirstLocation[]>([]);
    private isEnableDuplicateAwb = new BehaviorSubject<string | null>(null);
    private rangeAmount = new BehaviorSubject<RangeAmount[]>([]);
    private showErrorAmount = new BehaviorSubject<boolean>(false);

    constructor(private customerService: CustomerService) {}

    getPaymentReferenceWithinApprovalLevels(): PaymentReference[] {
        const customer: Customer | null = this.customerService.getCustomer();
        return (
            customer?.approvalLevels?.company?.paymentReference?.filter((reference) => reference.name === 'Type') || []
        );
    }

    setUser(user: User): void {
        this.user.next(user);
    }

    private getUser$(): Observable<User | null> {
        return this.user.asObservable();
    }

    get instant_customer_fields(): DynamicField[] {
        return this.user?.value?.dynamicFields || [];
    }

    getCustomerFields(): Observable<DynamicField[]> {
        return this.getUser$().pipe(
            map((user) => {
                return (
                    user?.dynamicFields?.filter((field) => {
                        const isUserDomain = this.customerService.isUserDomain();
                        const isCompany = this.customerService.getCompanyName();
                        if (isUserDomain && !isCompany) {
                            return !!field.emailDomain;
                        }

                        return true;
                    }) || []
                );
            })
        );
    }

    getCustomerSections(): Observable<DynamicSections[]> {
        return this.getUser$().pipe(
            map((user) => {
                return user?.dynamicSections || [];
            }),

            map((dynamicSections) => {
                const company = this.customerService.getCompanyName();
                return dynamicSections.map((element) => {
                    if (element.orderNumber === 0 && company) {
                        element.label = element?.label?.replace('Company', company);
                        element.title = element?.label?.replace('Company', company);
                    }
                    return element;
                });
            })
        );
    }

    getFirstLocationId(): number | null {
        const customer = this.customerService.getCustomer();
        return customer?.approvalLevels?.company?.firstLocation?.id || null;
    }

    hasGlCodes(): boolean {
        return !!this.customerService.getCustomer()?.hasGlCodes;
    }

    getCustomerReferenceFieldsBuilder(): getPaymentCustomerReferenceMethod {
        return this.user.value.getPaymentCustomerReference;
    }

    getCustomerPaymentDetailsFieldsBuilder(): getPaymentDetailsMethod {
        return this.user.value.getPaymentDetails;
    }

    getCompanyCustomerReferenceFieldsBuilder(): getCompanyCustomerReferenceMethod {
        return this.user.value.getCompanyCustomerReference;
    }

    getStandardRequestDataFieldsBuilder(): getStandardRequestDataMethod {
        return this.user.value.getStandardRequestData;
    }

    getRequestDataFieldsBuilder(): getRequestDataMethod {
        return this.user.value.getRequestData;
    }

    getFirstLocation(): FirstLocation | null {
        const customer: Customer | null = this.customerService.getCustomer();
        return customer?.approvalLevels?.company?.firstLocation || null;
    }

    getAmount(): OptionType[] {
        const customer: Customer | null = this.customerService.getCustomer();
        const approvalLevels: PaymentReference[] | undefined =
            customer?.approvalLevels?.company?.paymentReference?.filter((item: PaymentReference) => {
                return item.name === 'Type';
            });
        if (approvalLevels && approvalLevels.length > 0) {
            return (
                approvalLevels[0].paymentReferenceLookups?.map((paymentReferenceLookup) => {
                    return {
                        displayValue: paymentReferenceLookup.type || '',
                        value: paymentReferenceLookup.type || '',
                    };
                }) || []
            );
        }
        return [];
    }

    getCriteriaByPaymentAmount(type: string): PaymentReferenceLookup | null {
        const customer: Customer | null = this.customerService.getCustomer();
        const approvalLevels: PaymentReference[] | undefined =
            customer?.approvalLevels?.company?.paymentReference?.filter((item: PaymentReference) => {
                return item.name === 'Type';
            });
        if (approvalLevels && approvalLevels.length > 0) {
            return (
                approvalLevels[0].paymentReferenceLookups?.find((paymentReferenceLookup) => {
                    return paymentReferenceLookup.type === type;
                }) || null
            );
        }
        return null;
    }

    isStartingLevel(): boolean {
        const customer: Customer | null = this.customerService.getCustomer();
        return !!customer?.approvalLevels?.company?.startingLevel;
    }

    getCompanyName(): string {
        return this.customerService.getCompanyName();
    }

    findLocations(id: number): FirstLocation | null {
        return this.locations?.value.find((location) => location?.id === id) || null;
    }

    saveAllLocation(locations: FirstLocation[]): void {
        this.locations.next(locations);
    }

    setLocation(location: FirstLocation | null): void {
        if (location) {
            this.location = location;
        }
    }

    getLocation(): FirstLocation {
        return this.location;
    }

    getCustomer(): Customer | null {
        return this.customerService.getCustomer();
    }

    isUniqueRequestor(): boolean {
        return this.customerService.isUniqueRequestor();
    }

    setIsEnableDuplicateAwb(awb: string | null): void {
        this.isEnableDuplicateAwb.next(awb);
    }

    getIsEnableDuplicateAwb(): Observable<string | null> {
        return this.isEnableDuplicateAwb.asObservable();
    }

    setAmount(rangeAmount: RangeAmount[]): void {
        this.rangeAmount.next(rangeAmount);
    }

    getRangeAmount(): Observable<RangeAmount[]> {
        return this.rangeAmount.asObservable();
    }

    isEnableApprovalFlowLevels(type?: string): boolean {
        if (!type) {
            return false;
        }
        const canCreateApprovalFlow: CanCreateApprovalFlow[] | undefined =
            this.getCustomer()?.approvalLevels?.canCreateApprovalFlow?.filter((item) => item.name?.includes(type));
        return canCreateApprovalFlow && canCreateApprovalFlow[0].approvalFlowLevels
            ? canCreateApprovalFlow[0].approvalFlowLevels.length > 1
            : false;
    }

    setErrorAmount(showErrorAmount: boolean): void {
        this.showErrorAmount.next(showErrorAmount);
    }

    getErrorAmount(): Observable<boolean> {
        return this.showErrorAmount.asObservable().pipe(distinctUntilChanged());
    }
}
