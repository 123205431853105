import { Facility, PaymentExternalData } from '@cargos/sprintpay-models';
import { CompanyCustomerReference } from './new-payments/CompanyCustomerReference.model';
import { CustomerReference } from './new-payments/customerReference.model';
import { PaymentDetails } from './new-payments/paymentDetails.model';

export class Payment {
    constructor(
        public details?: PaymentDetails,
        public facility?: Facility,
        public id?: string,
        public location?: string,
        public reference?: CustomerReference,
        public schenkerCustomerReference?: CompanyCustomerReference,
        public geodisCustomerReference?: CompanyCustomerReference,
        public rhenusCustomerReference?: CompanyCustomerReference,
        public dhlCustomerReference?: CompanyCustomerReference,
        public emotransCustomerReference?: CompanyCustomerReference,
        public knCustomerReference?: CompanyCustomerReference,
        public customerDHL?: CompanyCustomerReference,
        public source?: string,
        public dynamicValues?: any,
        public step?: number,
        public fromCart?: boolean,
        public redirectRoute?: string,
        public bulkPaymentByPort?: any,
        public bulkPaymentByFacility?: any,
        public sprintPaySource?: boolean,
        public externalData?: PaymentExternalData,
        public approvalStatus?: PaymentApprovalStatus | string,
        public uniqueRequestor?: boolean,
        public ownerCanPay?: boolean,
        public sameCustomerAndRequestor?: boolean,
        public externalLookup?: boolean,
        public companyCustomerReference?: CompanyCustomerReference,
        public paymentAmount?: string,
        public paymentType?: string,
        public type?: string,
        public externalDataType?: string,
        public costCenter?: string,
        public voucher?: string,
        public payType?: string
    ) {}

    static fromJson({
        details,
        facility,
        id,
        location,
        reference,
        schenkerCustomerReference,
        geodisCustomerReference,
        rhenusCustomerReference,
        dhlCustomerReference,
        emotransCustomerReference,
        knCustomerReference,
        customerDHL,
        source,
        dynamicValues,
        step,
        fromCart,
        redirectRoute,
        bulkPaymentByPort,
        bulkPaymentByFacility,
        sprintPaySource,
        externalData,
        approvalStatus,
        uniqueRequestor,
        ownerCanPay,
        sameCustomerAndRequestor,
        externalLookup,
        companyCustomerReference,
        paymentAmount,
        paymentType,
        type,
        externalDataType,
        costCenter,
        voucher,
        payType,
    }: PaymentProperties): Payment {
        return new Payment(
            details,
            facility,
            id,
            location,
            reference,
            schenkerCustomerReference,
            geodisCustomerReference,
            rhenusCustomerReference,
            dhlCustomerReference,
            emotransCustomerReference,
            knCustomerReference,
            customerDHL,
            source,
            dynamicValues,
            step,
            fromCart,
            redirectRoute,
            bulkPaymentByPort,
            bulkPaymentByFacility,
            sprintPaySource,
            externalData,
            approvalStatus,
            uniqueRequestor,
            ownerCanPay,
            sameCustomerAndRequestor,
            externalLookup,
            companyCustomerReference,
            paymentAmount,
            paymentType,
            type,
            externalDataType,
            costCenter,
            voucher,
            payType
        );
    }
}

export interface PaymentProperties {
    details?: PaymentDetails;
    facility?: Facility;
    id?: string;
    location?: string;
    reference?: CustomerReference;
    schenkerCustomerReference?: CompanyCustomerReference;
    geodisCustomerReference?: CompanyCustomerReference;
    rhenusCustomerReference?: CompanyCustomerReference;
    dhlCustomerReference?: CompanyCustomerReference;
    emotransCustomerReference?: CompanyCustomerReference;
    knCustomerReference?: CompanyCustomerReference;
    customerDHL?: CompanyCustomerReference;
    source?: string;
    dynamicValues?: any;
    step?: number;
    fromCart?: boolean;
    redirectRoute?: string;
    bulkPaymentByPort?: any;
    bulkPaymentByFacility?: any;
    sprintPaySource?: boolean;
    externalData?: PaymentExternalData;
    approvalStatus?: PaymentApprovalStatus | string;
    uniqueRequestor?: boolean;
    ownerCanPay?: boolean;
    sameCustomerAndRequestor?: boolean;
    externalLookup?: boolean;
    companyCustomerReference?: CompanyCustomerReference;
    paymentAmount?: string;
    paymentType?: string;
    type?: string;
    externalDataType?: string;
    costCenter?: string;
    voucher?: string;
    payType?: string;
}

export enum PaymentApprovalStatus {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}
