export const InvalidAWBList = [
    '000-10002024',
    '000-10012024',
    '000-10022024',
    '000-10032024',
    '000-10042024',
    '000-10052024',
    '000-10062024',
    '000-10072024',
];
