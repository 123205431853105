import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApplyForCargoFacilityComponent } from '../../apply-for-cargo-facility.component';
import { SecurityFormGroupType } from '../../models/form-types';

@Component({
    selector: 'app-security-step',
    templateUrl: './security-step.component.html',
    styleUrl: './security-step.component.scss',
})
export class SecurityStepComponent implements OnInit {
    @Input() email: string | null;
    public parentForm: SecurityFormGroupType;

    constructor(
        public dialogRef: MatDialogRef<ApplyForCargoFacilityComponent>,
        private controlContainer: ControlContainer
    ) {}

    ngOnInit(): void {
        this.parentForm = this.controlContainer.control as SecurityFormGroupType;
    }

    completeProcess(): void {
        const request = {};
        this.dialogRef.close(true);
    }

    onSetPassword(password: string): void {
        this.parentForm.controls['password'].setValue(password);
    }
}
