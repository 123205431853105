<div class="row" *ngIf="keys()?.length">
    <!-- START: COMPANY CUSTOMER REFERENCE TITLE -->
    <div class="detail__sections mb-4">
        <div class="col-12">
            <div class="d-flex justify-content-between">
                <span class="my-3"><strong>{{companyName}} Customer Reference</strong></span>
                @if (isVisibleEditButton) {
                <button mat-button class="my-2 px-0 text-uppercase text-decoration-none" (click)="editPaymentRequest()">
                    Edit
                </button>
                }
            </div>
        </div>
    </div>
    <!-- END: COMPANY CUSTOMER REFERENCE TITLE -->
    <!-- START: DYNAMIC FIELDS -->
    <ng-container *ngFor="let key of keys();">
        <div class="col-12 col-md-4" *ngIf="companyReferenceObjCopy[key]">
            <p class="card-subtitle me-2"><small>{{companyReferenceTitle[key]}}</small></p>
            <p>{{companyReferenceObjCopy[key] || 'N/A'}}</p>
        </div>
    </ng-container>
    <!-- END: DYNAMIC FIELDS -->
    <!-- START: ATTACHMENT DOWNLOADED LINK -->
    <app-files-container [filesPath]="pathFiles" [title]="titleFile"></app-files-container>
    <!-- END: ATTACHMENT DOWNLOADED LINK -->
</div>
