import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-request-received',
    templateUrl: './request-received.component.html',
    styleUrl: './request-received.component.scss',
})
export class RequestReceivedComponent {
    constructor(public dialogRef: MatDialogRef<RequestReceivedComponent>) {}

    close(): void {
        this.dialogRef.close();
    }
}
