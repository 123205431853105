/**
 *
 * This service is a **helper** to storage actions by the user like
 * *what payment method is selected
 * *what AWB was searched
 * *what Location was searched
 *
 */
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PaymentMethodsType, ShipmentInfo } from '@cargos/sprintpay-models';
import { StorageService, StorageTypes } from '@cargos/sprintpay-services';
import { BehaviorSubject, Observable, distinctUntilChanged, map } from 'rxjs';
import { PaymentMethodsService } from 'src/app/services/payment-methods.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { CustomerReferenceFormGroupType } from 'src/app/shared/models/customer-reference-form';
import { PaymentStatusType } from 'src/app/shared/models/payment-status';
import { SearchType } from 'src/app/shared/models/search-type';
import { PaymentMethodSelected } from './models/types';

@Injectable({
    providedIn: 'root',
})
export class SummaryService {
    private _paymentMethodSelected = new BehaviorSubject<PaymentMethodSelected | null>(null);
    private _customerReferenceForm = new BehaviorSubject<CustomerReferenceFormGroupType | null>(null);
    private _mawb: string | null = null;
    private _location = new BehaviorSubject<string | null>(null);
    private _searchType = new BehaviorSubject<SearchType | null>(null);
    private _paymentStatus = new BehaviorSubject<PaymentStatusType | null>(null);
    private _pickupDate = new BehaviorSubject<string | null>(null);
    private _flightActive = new BehaviorSubject<{
        flightNumber: string;
        flightDate: string;
    } | null>(null);
    private _splitShipment = new BehaviorSubject<boolean>(false);
    private _costCenter = new BehaviorSubject<string>('');
    private _pickupTime = new BehaviorSubject<string | null>(null);
    private _firmsCode = '';
    private _collectorForms = new BehaviorSubject<(FormGroup | null)[]>([]);

    constructor(
        private storageService: StorageService,
        private tokenService: TokenService,
        private paymentMethodsService: PaymentMethodsService
    ) {
        const shipment_info: ShipmentInfo = this.storageService.getElement('shipment_info', StorageTypes.SESSION);
        this.setMAWB(shipment_info && shipment_info.awb);
        const location: string | null = this.storageService.getElement('location', StorageTypes.SESSION);
        this.setLocation(location);
        const searchType: SearchType | null = this.storageService.getElement('searchType', StorageTypes.SESSION);
        this.setSearchType(searchType);
    }

    get instant_payment_method_selected(): PaymentMethodSelected | null {
        return this._paymentMethodSelected.value;
    }

    getPaymentMethodNameSelected$(): Observable<PaymentMethodsType | null> {
        return this.getPaymentMethodSelected$().pipe(
            map((methodSelected: PaymentMethodSelected | null) => methodSelected?.name || null),
            distinctUntilChanged<PaymentMethodsType | null>()
        );
    }

    /**
     *
     * @returns {PaymentMethodSelected} The payment method selected (name and token in case is credit-card or echeck)
     *
     */
    getPaymentMethodSelected$(): Observable<PaymentMethodSelected | null> {
        return this._paymentMethodSelected.asObservable().pipe(distinctUntilChanged());
    }

    setPaymentMethodSelected(values: PaymentMethodSelected | null): void {
        this._paymentMethodSelected.next(
            values
                ? {
                      id: 'authenticatedPaymentMethod',
                      ...values,
                  }
                : values
        );
    }

    isPaymentMethodSelected(): Observable<boolean> {
        return this.getPaymentMethodSelected$().pipe(map((paymentMethodToken) => !!paymentMethodToken));
    }

    get instant_location_id(): number | null {
        return this._customerReferenceForm?.value?.value?.locationId || null;
    }

    setMAWB(mawb: string): void {
        this._mawb = mawb;
    }

    getMAWB(): string | null {
        return this._mawb;
    }

    setAndSaveLocationInStorage(location: string | null): void {
        this.setLocation(location);
        this.saveLocation(location);
    }

    private setLocation(location: string | null): void {
        this._location.next(location);
    }

    private saveLocation(location: string | null): void {
        this.storageService.saveElement('location', location, StorageTypes.SESSION);
    }

    getLocation(): string | null {
        return this._location.value;
    }

    getLocation$(): Observable<string | null> {
        return this._location.asObservable().pipe(distinctUntilChanged());
    }

    setAndSaveSearchTypeInStorage(searchType: SearchType | null): void {
        this.setSearchType(searchType);
        this.saveSearchType(searchType);
    }

    private setSearchType(searchType: SearchType | null): void {
        this._searchType.next(searchType);
    }

    private saveSearchType(searchType: SearchType | null): void {
        this.storageService.saveElement('searchType', searchType, StorageTypes.SESSION);
    }

    getSearchType(): string | null {
        return this._searchType.value;
    }

    getSearchType$(): Observable<SearchType | null> {
        return this._searchType.asObservable();
    }

    newCurrentPaymentStatusEvent(event: PaymentStatusType): void {
        this._paymentStatus.next(event);
    }

    onCurrentPaymentStatusEvent$(): Observable<PaymentStatusType | null> {
        return this._paymentStatus.asObservable();
    }

    get instant_payment_status(): PaymentStatusType | null {
        return this._paymentStatus.value;
    }

    get instant_pickup_date(): string | null {
        return this._pickupDate.value;
    }

    setPickUpDate(pickupDate: string): void {
        this._pickupDate.next(pickupDate);
    }

    getPickUpDate(): Observable<string | null> {
        return this._pickupDate.asObservable();
    }

    get instant_pickup_time(): string | null {
        return this._pickupDate.value;
    }

    setPickUpTime(pickupTime: string): void {
        this._pickupTime.next(pickupTime);
    }

    getPickUpTime(): Observable<string | null> {
        return this._pickupTime.asObservable();
    }

    setFlightActive(flightNumber: string, flightDate: string): void {
        this._flightActive.next({ flightNumber, flightDate });
    }

    getFlightActive(): Observable<{ flightNumber: string; flightDate: string } | null> {
        return this._flightActive.asObservable();
    }

    get instant_is_split_shipment(): boolean {
        return this._splitShipment.value;
    }

    isSplitShipment$(): Observable<boolean> {
        return this._splitShipment.asObservable();
    }

    setSplitShipmentStatus(isSplit: boolean): void {
        this._splitShipment.next(isSplit);
    }

    get instant_cost_center(): string {
        return this._costCenter.value;
    }

    getCostCenter$(): Observable<string> {
        return this._costCenter.asObservable();
    }

    setCostCenter(costCenter: string): void {
        this._costCenter.next(costCenter);
    }

    /**
     * This firms code is input by the customer when is searching by AWB
     */
    setFirmsCode(firmsCode: string) {
        this._firmsCode = firmsCode;
    }

    getFirmsCode(): string {
        return this._firmsCode;
    }
}
