import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IMaskModule } from 'angular-imask';
import { InvalidFieldComponent } from 'src/app/shared/components/invalid-field/invalid-field.component';
import { AWBSearchMaterialModule } from './awb-search-material.module';
import { AwbSearchComponent } from './awb-search.component';

@NgModule({
    declarations: [AwbSearchComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        AWBSearchMaterialModule,
        IMaskModule,
        InvalidFieldComponent,
    ],
    exports: [AwbSearchComponent],
})
export class AwbSearchModule {}
