<div class="form-inputs">
    <div class="awb-search-form row">
        <form [formGroup]="searchForm" (submit)="submit(searchForm)">
            <mat-form-field appearance="outline" class="input_width col-12 mt-2">
                <mat-label>Please enter your MAWB</mat-label>
                <input
                    [imask]="{mask: maskAwb}"
                    formControlName="mawb"
                    matInput
                    type="text"
                    class="input-awb"
                    placeholder="Please enter your MAWB"
                    [readonly]="isLoading"
                    autocomplete="off"
                    (keydown.enter)="$event.preventDefault();submit(searchForm);"
                />
                <button
                    type="submit"
                    *ngIf="!isCompleted"
                    color="primary"
                    mat-button
                    matTextSuffix
                    [disabled]="isLoading || disabled"
                >
                    SEARCH
                </button>
                <button
                    type="button"
                    *ngIf="isCompleted"
                    mat-icon-button
                    [disabled]="isLoading"
                    (click)="cleanInput()"
                    class="me-2"
                    matIconSuffix
                >
                    <mat-icon>close</mat-icon>
                </button>
                <mat-icon *ngIf="!isLoading" matIconPrefix>search</mat-icon>
                <mat-icon *ngIf="isLoading" matIconPrefix>
                    <mat-spinner diameter="24"></mat-spinner>
                </mat-icon>
                <mat-error>
                    <app-invalid-field [field]="'mawb'" [messageList]="formError['mawb']"> </app-invalid-field>
                </mat-error>
            </mat-form-field>
            <!-- <button
                mat-raised-button
                type="submit"
                class="w-100 my-4"
                color="primary"
                [disabled]="isLoading || disabled"
            >
                {{ isLoading ? 'SEARCHING': 'SEARCH'}}
                <mat-icon *ngIf="isLoading" matIconPrefix>
                    <mat-spinner diameter="24"></mat-spinner>
                </mat-icon>
            </button> -->
        </form>
    </div>
</div>
