import { ComponentType } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, OnDestroy, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StatService } from '@cargos/sprintpay-services';
import { LoginAsCargoSprintUserResponse } from '@cargos/sprintpay-services/lib/apis/sprintpay/auth/models/auth-types';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, distinctUntilChanged, finalize, map, of, switchMap, take, takeUntil } from 'rxjs';
import { LoginForm } from 'src/app/models/auth/signup.model';
import { CustomerService } from 'src/app/services';
import { GuestService } from 'src/app/services/guest.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { AuthService } from 'src/app/services/utils/auth.service';
import { RoutingService } from 'src/app/services/utils/routing/routing.service';
import { StorageService } from 'src/app/services/utils/storage.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { UserSessionService } from 'src/app/services/utils/user/user-session.service';
import { profileTypes, regex, sprintPaySource } from 'src/app/utils/constants';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { HelpContactusService } from '../../../services/help-contactus.service';
import { ErrorHandlerService } from '../../../services/utils/error-handler.service';
import { ErrorMatcher } from '../../../utils/error-matcher';
import { LoginService } from '../services/login.service';
import { ApplyForCargoFacilityComponent } from './apply-for-cargo-facility/apply-for-cargo-facility.component';
import { RequestReceivedComponent } from './apply-for-cargo-facility/request-received/request-received.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm!: FormGroup;
    passwordHintHide: boolean;
    matcher: ErrorMatcher;
    private unsubscribe$: Subject<void>;
    public noSSOLogin = false;
    private loginSSOTarget = '_self';
    private path: string = '';

    @ViewChild('authenticationFlux') authenticationFlux?: ComponentType<unknown>;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private ngxSpinnerService: NgxSpinnerService,
        private errorHandlerService: ErrorHandlerService,
        private tokenService: TokenService,
        public helpContactusService: HelpContactusService,
        private loginService: LoginService,
        private activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private guestService: GuestService,
        private domSanitizer: DomSanitizer,
        private userSessionService: UserSessionService,
        private storageService: StorageService,
        private customerService: CustomerService,
        private ngZone: NgZone,
        private summaryService: SummaryService,
        private routingService: RoutingService,
        private statService: StatService,
        public dialog: MatDialog
    ) {
        this.passwordHintHide = true;
        this.matcher = new ErrorMatcher();
        this.storageService.cleanStorage();
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this.subscribeParams();
        this.onEmailChange();
        this.modalHandler();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    modalHandler(): void {
        this.route.queryParams.subscribe((params) => {
            if (params['applyForCargoFacility'] === 'true') {
                const dialogRef = this.dialog.open(ApplyForCargoFacilityComponent, {
                    width: '700px',
                });

                dialogRef
                    .afterClosed()
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((result) => {
                        if (result) {
                            const dialogRef = this.dialog.open(RequestReceivedComponent, {
                                width: '700px',
                            });
                        }
                    });
            }
        });
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.loginForm = this.formBuilder.group(
            {
                email: new FormControl<string | null>(null, [
                    Validators.required,
                    Validators.minLength(5),
                    Validators.pattern(regex.email),
                ]),
                password: new FormControl<string | null>(null),
            },
            { updateOn: 'change' }
        );
    }

    /**
     * @method email()
     * @description: Convenience getter for easy access to form fields
     */

    get getEmail(): FormGroup {
        return this.loginForm.get('email') as FormGroup;
    }

    /**
     * @method password()
     * @description: Convenience getter for easy access to form fields
     */

    get getPassword(): FormGroup {
        return this.loginForm.get('password') as FormGroup;
    }

    /**
     * @method setEmail()
     * @param (event: {[key: string]: string})
     * @description Convenience setter for easy access to form fields
     */
    set setEmail(event: { [key: string]: string }) {
        this.getEmail!.setValue(event);
    }

    /**
     * @method setPassword()
     * @param (event: {[key: string]: string})
     * @description Convenience setter for easy access to form fields
     */
    set setPassword(event: { [key: string]: string }) {
        this.getPassword!.setValue(event);
    }

    onEmailChange(): void {
        this.getEmail.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$)).subscribe(() => {
            this.setNoSSOLogin = false;
            this.getPassword?.removeValidators(Validators.required);
            this.loginForm.updateValueAndValidity();
        });
    }

    /**
     * @method onSubmit()
     * @description Submission action
     */

    onSubmit(): void {
        if (this.loginForm.invalid) {
            return;
        }

        this.ngxSpinnerService.show();

        const user: LoginForm = {
            username: this.getEmail.value,
            password: this.getPassword.value,
        };

        of(this.noSSOLogin)
            .pipe(
                take(1),
                switchMap((noSSOLogin) => (!noSSOLogin ? this.SSOLogin(user.username) : this.standardLogin(user)))
            )
            .subscribe({
                next: (response) => {
                    const { authorizationToken, refreshToken, redirectUrl } = response;
                    if (response.loginFlow === 'SSO') {
                        this.validateSSO(redirectUrl);
                    } else if (authorizationToken && refreshToken) {
                        const spCustomer = JSON.parse(
                            JSON.stringify(this.tokenService.getDecodedAccessToken(authorizationToken))
                        )?.SPRINT_PAY_CUSTOMER;
                        const customer = JSON.parse(spCustomer)?.customer;
                        const customerProfile =
                            customer?.profileType && customer.profileType !== profileTypes.FORWARDER_FACILITY
                                ? customer.profileType
                                : profileTypes.FORWARDER;
                        this.tokenService.setProfileType(customerProfile);
                        this.customerService.setActiveProfileView(customerProfile);
                        this.authService.initConfigTokens({ authorizationToken, refreshToken });
                        this.userSessionService.postTokens({ authorizationToken, refreshToken });
                        this.customerService.registerUser({ email: this.getEmail.value });
                        const url: string = this.path === '' ? this.loginService.getRedirectLink() : this.path;
                        this.ngZone.run(() => this.router.navigateByUrl(url));
                    }
                },
                error: (error: HttpErrorResponse) => {
                    const errorData = error.error?.error?.data;
                    const isCredentialsInvalid =
                        error.error?.error?.title &&
                        error.error.error.title.toString().toLowerCase().includes('invalid credentials');

                    if (errorData && 'isGuest' in errorData && 'isActive' in errorData && !isCredentialsInvalid) {
                        const { isGuest, isActive, dynamicParameter } = errorData;
                        const email = user.username;

                        if (!isGuest && !isActive && dynamicParameter.activationUuid) {
                            this._handleInactiveAccount(email, dynamicParameter.activationUuid);
                        } else if (!isGuest && isActive) {
                            this._showErrorPopup(error);
                        } else if (isGuest && !isActive) {
                            this.summaryService.setGuestPaymentInformation({ personalInformation: { email } });
                            this.router.navigate(['/signup/sprintpay/account-type/' + email]);
                        }
                    } else {
                        this._showErrorPopup(error);
                    }
                },
            });
    }

    /**
     * @method signInAsGuest()
     * @description Sign in as guest action
     */

    signInAsGuest(): void {
        this.guestService.showPaymentLimitPopup().then((result) => {
            if (result.isConfirmed) {
                this.ngxSpinnerService.show();
                this.userSessionService
                    .signInAsGuest()
                    .pipe(
                        take(1),
                        finalize(() => {
                            this.ngxSpinnerService.hide();
                        })
                    )
                    .subscribe({
                        next: (token) => {
                            if (token) {
                                const url = 'admin/facilityPayments/newPayment';
                                this.router.navigate([url]);
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            Swal.fire({
                                html: `${this.errorHandlerService.errorTemplate(error.error)}`,
                                icon: 'error',
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: 'Close',
                                allowOutsideClick: false,
                            });
                        },
                    });
            }
        });
    }

    subscribeParams(): void {
        this.getParams()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((params) => {
                if (params.type && params.type === 'guest') {
                    this.signInAsGuest();
                } else if (params.path) {
                    this.path = this.routingService.getRoute(params.path);
                }
            });
    }

    getParams(): Observable<{
        type: string;
        path: string;
    }> {
        return this.activatedRoute.queryParams.pipe(
            map((params) => ({
                type: params['type'],
                path: params['path'],
            }))
        );
    }

    private _handleInactiveAccount(email: string, activationUuid: string): void {
        this.userSessionService
            .signInAsGuest()
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.matDialog.open(this.authenticationFlux as ComponentType<unknown>, {
                        disableClose: true,
                        width: '55em',
                        data: {
                            email,
                            activationUuid,
                            accountVerifiedHandler: () => this.userSessionService.logout(),
                        },
                    });
                },
            });
    }

    private _showErrorPopup(error: unknown): void {
        const swalOptions: SweetAlertOptions = {
            icon: 'error',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Close',
            allowOutsideClick: false,
        };

        if (error instanceof HttpErrorResponse && error?.error?.error?.title) {
            swalOptions.title = 'There was an error with your information, please see below.';
            swalOptions.text = error?.error?.error?.body || 'Invalid username or password';
        } else if (error instanceof HttpErrorResponse) {
            swalOptions.html = `${this.errorHandlerService.errorTemplate(error.error)}`;
        }

        Swal.fire(swalOptions);
    }

    private validateSSO(redirectUrl: string | undefined): void {
        if (redirectUrl) {
            const url = this.statService.isFeatureEnabled('sso')
                ? `${redirectUrl}-${sprintPaySource}`
                : `${redirectUrl}?source=${sprintPaySource}`;

            const sanitizedUrl: string | null = this.domSanitizer.sanitize(
                SecurityContext.RESOURCE_URL,
                this.domSanitizer.bypassSecurityTrustResourceUrl(url || '')
            );

            window.open(sanitizedUrl || '', this.loginSSOTarget);
        } else {
            const email = this.getEmail?.value || '';
            this.getPassword?.addValidators(Validators.required);
            this.getPassword?.updateValueAndValidity();
            let errors = this.getPassword?.errors;
            if (errors && 'required' in errors) {
                this.loginForm?.reset();
                this.getEmail?.setValue(email);
            }
            this.setNoSSOLogin = true;
        }
    }

    private standardLogin(user: LoginForm): Observable<LoginAsCargoSprintUserResponse> {
        return this.authService.signIn(user).pipe(
            switchMap((result: LoginAsCargoSprintUserResponse) => {
                return this.loginService
                    .initLogin(result.authorizationToken || '')
                    .pipe(map(() => ({ ...result, loginFlow: 'STANDARD' as const })));
            }),
            finalize(() => {
                this.tokenService.setAuthorizationTokenId(null);
                this.ngxSpinnerService.hide();
            })
        );
    }

    private SSOLogin(email: string): Observable<LoginAsCargoSprintUserResponse> {
        return this.authService.validateEmailSSO(email).pipe(
            take(1),
            map((response) => ({ ...response, loginFlow: 'SSO' as const })),
            finalize(() => this.ngxSpinnerService.hide())
        );
    }

    private set setNoSSOLogin(value: boolean) {
        this.noSSOLogin = value;

        if (!value) {
            this.getPassword.clearValidators();
            this.getPassword.reset();
        }
    }

    public get passwordRequired(): boolean {
        return this.getPassword.hasError('required');
    }
}
