<mat-dialog-content>
    <img class="my-5 mx-auto d-block" src="assets/images/cargosprint/cargosprint-logo.svg" alt="Cargo Sprint Logo" />
    <img class="my-5 mx-auto d-block" src="assets/images/illustration/pay.svg" alt="Illustration Pay" />
    <p class="header text-center">Request Received</p>
    <p class="description text-center mt-4 mx-5 px-5">
        You'll receive an email notification once your application is approved or declined. In the meantime, your
        SprintPay account has been created.
    </p>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center">
    <button
        id="close-button"
        type="button"
        color="primary"
        class="w-50 border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green my-5"
        mat-button
        (click)="close()"
    >
        Go to Sprintpay Sign In
    </button>
</mat-dialog-actions>
