import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-next-previous-buttons',
    templateUrl: './next-previous-buttons.component.html',
    styleUrl: './next-previous-buttons.component.scss',
})
export class NextPreviousButtonsComponent {
    @Input() previousText?: string = 'Previous';
    @Input() nextText?: string = 'Next';
    @Input() disabled?: boolean = false;
    @Input() customPreviousHandler?: boolean = false;
    @Input() customNextHandler?: boolean = false;
    @Output() previous = new EventEmitter();
    @Output() next = new EventEmitter();

    previousHandler(): void {
        this.previous.emit();
    }

    nextHandler(): void {
        this.next.emit();
    }
}
