import { SearchDescriptionException, SearchExceptionType } from './search-exceptions-const';

export class SearchException {
    constructor(
        public code: SearchExceptionType,
        public description: string,
        public title = 'Oops!'
    ) {}

    static fromJson({ code, description, title }: SearchExceptionTypes): SearchException {
        const desc = description ? description : SearchDescriptionException[code];

        return new SearchException(code, desc, title);
    }
}

export interface SearchExceptionTypes {
    code: SearchExceptionType;
    description?: string;
    title?: string;
}
