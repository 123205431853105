import { MediaMatcher } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, InjectionToken, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DynamicDialogData } from './models/dynamic-dialog-data';

export const MULTIPLIER = new InjectionToken<number>('MULTIPLIER');

@Component({
    selector: 'app-dynamic-dialog',
    templateUrl: './dynamic-dialog.component.html',
    styleUrl: './dynamic-dialog.component.scss',
    standalone: true,
    imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule],
})
export class DynamicDialogComponent implements OnInit, OnDestroy {
    public customInjector: Injector;
    public mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;

    constructor(
        private matDialog: MatDialogRef<DynamicDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DynamicDialogData<unknown, unknown>,
        private media: MediaMatcher,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.mobileQuery = this.media.matchMedia('(max-width: 767px)');
        /**
         * Initial size of the dialog
         */
        this.updateTableSize();
        /**
         * Listener for window size changes and update the dialog size
         */
        this._mobileQueryListener = () => {
            this.updateTableSize();
            this.changeDetectorRef.detectChanges();
        };
    }

    ngOnInit(): void {
        this.subscribeWindowSize();
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }

    updateTableSize(): void {
        this.matDialog.updateSize(
            this.mobileQuery.matches ? 'auto' : this.data.config?.width || '882px',
            this.mobileQuery.matches ? '100vh' : this.data.config?.height || 'auto'
        );
    }

    subscribeWindowSize(): void {
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    }
}
