export enum SearchExceptionType {
    INVALID_AWB = 1,
    NOT_ALLOWED_MULTIPLE_AWBS,
    UNKNOWN,
}

export const SearchDescriptionException = Object.freeze({
    [SearchExceptionType.INVALID_AWB]: 'The AWB entered is not a valid AWB number, please enter a valid value',
    [SearchExceptionType.NOT_ALLOWED_MULTIPLE_AWBS]: 'Please reset your cart to add a new AWB.',
    [SearchExceptionType.UNKNOWN]: 'Something went wrong.',
});
