import { Injectable } from '@angular/core';
import { paths } from './paths';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    getRoute(queryParam): string {
        const idPath: number = this.decrypt(queryParam);
        const path: string = this.lookForPath(idPath);
        return path;
    }

    lookForPath(id: number): string {
        return paths.find((path) => path.id === id)?.path || '/';
    }

    private decrypt(queryParam: string): number {
        return Number(atob(queryParam));
    }
}
