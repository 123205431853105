<form [formGroup]="parentForm">
    <div class="mt-2">
        <app-form-password (eventOnPassword)="onSetPassword($event)"></app-form-password>

        <p class="two-factor-auth mb-2">TWO FACTOR AUTHENTICATION</p>
        <p class="two-factor-auth-description mb-2">
            This is an extra layer of security that helps protect your account by requering a form of verification
            before granting access. It adds an additional step to the usual username and password login process.
        </p>

        <div class="email-container">
            <p class="email-value">{{ email }}</p>
            <p class="email-legend">We will send an email with a code to your email address.</p>
        </div>

        <mat-checkbox formControlName="acceptedTerms"></mat-checkbox>
        I accept CargoSprint’s
        <a href="https://cargosprint.com/legal/terms-of-use/sprintpay/" target="_blank">Terms of Use</a> &
        <a href="https://cargosprint.com/legal/privacy-policy/" target="_blank">Privacy Policy</a>

        <app-next-previous-buttons
            [disabled]="!parentForm.valid"
            [customNextHandler]="true"
            (click)="completeProcess()"
        ></app-next-previous-buttons>
    </div>
</form>
