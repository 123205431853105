<!--START: Login -->
<section id="login">
    <div class="overflow-hidden">
        <div class="row">
            <!--START: Left container -->
            <div class="d-none d-md-block col-md-6 col-lg-7 p-0">
                <!--START: Background image and overlay -->
                <div class="login__background w-100 vh-100 p-md-5 d-flex flex-grow-1 align-items-center">
                    <span class="d-flex align-items-center h-100 text-decoration-none w-100"
                        >Smarter Payments, Enhanced Experiences</span
                    >
                </div>
                <!--END: Background image and overlay -->
            </div>
            <!--END: Left container -->
            <!--END: Right container -->
            <div class="col-sm-12 col-md-6 col-lg-5 p-0">
                <div class="d-flex flex-column min-vh-100 align-items-center p-3">
                    <!--START: Floating button -->
                    <button
                        id="help-contact-us"
                        mat-stroked-button
                        (click)="helpContactusService.showHelpSwal()"
                        type="button"
                        class="btn__sprintpay btn__sprintpay--white help"
                        color="primary"
                    >
                        Help / Contact Us
                    </button>
                    <!--END: Floating button -->
                    <div class="w-75 mt-sm-1 mt-lg-5 d-flex flex-grow-1 flex-column justify-content-center main">
                        <!--START: Brand logo image -->
                        <div class="row">
                            <div class="w-100 mb-4 col-sm-12 col-md-10 col-lg-10 col-xl-10 mx-auto">
                                <img
                                    src="../../../../assets/images/sp-logo.svg"
                                    alt="Login brand logo"
                                    class="login__form--logo w-auto"
                                />
                            </div>
                        </div>
                        <!--END: Brand logo image -->
                        <!--START: Title and subtitle -->
                        <div class="row">
                            <div class="w-100 mb-2 col-sm-12 col-md-10 col-lg-10 col-xl-10 mx-auto">
                                <h6 id="welcome-title">Welcome to your payment platform</h6>
                            </div>
                        </div>
                        <!--END: Title and subtitle -->
                        <!--START: Login form -->
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="row">
                            <div class="w-100 col-sm-12 col-md-10 col-lg-10 col-xl-10 mx-auto">
                                <!--START: Email -->
                                <div class="form-group email__field">
                                    <mat-form-field class="d-block" appearance="outline">
                                        <mat-label>Email</mat-label>
                                        <input
                                            matInput
                                            type="email"
                                            id="email"
                                            name="email"
                                            data-cy="email"
                                            formControlName="email"
                                            placeholder="Enter your email"
                                            [errorStateMatcher]="matcher"
                                            [appAutofillPatch]="getEmail"
                                        />
                                        <mat-error
                                            id="error-email-malformed"
                                            *ngIf="loginForm.controls['email'].hasError('pattern')"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The email address
                                            was malformed! Please try again
                                        </mat-error>
                                        <mat-error
                                            id="email-required"
                                            *ngIf="loginForm.controls['email'].hasError('required')"
                                        >
                                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--END: Email -->
                                <!-- <ng-container *ngIf="isSSOEmail">
                                    <app-redirect-sso-email
                                        [redirectUrl]="redirectURL"
                                        (redirectCompleted)="resetEmailSSOStatus()"
                                    ></app-redirect-sso-email
                                ></ng-container> -->

                                <!--START: Password -->
                                <ng-container *ngIf="noSSOLogin">
                                    <div class="form-group">
                                        <mat-form-field class="d-block" appearance="outline">
                                            <mat-label>Password</mat-label>
                                            <input
                                                matInput
                                                [type]="passwordHintHide ? 'password' : 'text'"
                                                id="password"
                                                name="password"
                                                data-cy="password"
                                                formControlName="password"
                                                autocomplete="off"
                                                placeholder="Enter your password"
                                                [errorStateMatcher]="matcher"
                                                [appAutofillPatch]="getPassword"
                                            />
                                            <button
                                                type="button"
                                                mat-icon-button
                                                matSuffix
                                                (click)="passwordHintHide = !passwordHintHide"
                                                [attr.aria-label]="'Hide password'"
                                                [attr.aria-pressed]="passwordHintHide"
                                            >
                                                <mat-icon
                                                    >{{passwordHintHide ? 'visibility_off' : 'visibility'}}</mat-icon
                                                >
                                            </button>
                                            <mat-error *ngIf="passwordRequired">
                                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                <!--END: Password -->
                            </div>
                            <!--START: Forgot password button -->
                            <a
                                id="forgot-password-link"
                                [routerLink]="['/password/forgot-password']"
                                class="d-block text-center text-uppercase mb-2 h-100 text-decoration-none w-100 forgot__link fw-bolder"
                                >Forgot password?</a
                            >
                            <!--END: Forgot password button -->
                            <!--START: Submit button -->
                            <div class="col-12">
                                <button
                                    id="submit-btn"
                                    mat-flat-button
                                    type="submit"
                                    class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green"
                                    color="primary"
                                    [disabled]="loginForm.invalid"
                                >
                                    Next
                                </button>
                            </div>
                            <!--END: Submit button -->
                            <!--START: Guest user button -->
                            <div class="col-12">
                                <button
                                    id="continue-as-guest-btn"
                                    mat-stroked-button
                                    color="primary"
                                    type="button"
                                    class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--greenBorder btn__sprintpay--multiLine mt-3"
                                    (click)="signInAsGuest()"
                                >
                                    Continue as guest
                                </button>
                            </div>
                            <!--END: Guest user button -->
                        </form>
                        <!--END: Login form -->
                        <!--START: Extras -->
                        <div class="row">
                            <div class="w-100 col-sm-12 col-md-10 col-lg-10 col-xl-10 mx-auto mt-4 mb-4 extra">
                                <div class="text-center mb-2">
                                    <span>Don't have an account?</span><span>&nbsp;&nbsp;</span
                                    ><a
                                        id="sign-up-link"
                                        class="extra__account extra__account--link text-decoration-none fw-bolder"
                                        [routerLink]="['/signup/sprintpay/account-type']"
                                        >Sign up</a
                                    >
                                </div>
                                <div class="text-center mb-2">
                                    <span>Are you a FedEx user?</span
                                    ><span class="extra__terms fw-bolder">&nbsp;&nbsp;</span
                                    ><a
                                        class="text-decoration-none extra__fedexuser extra__fedexuser--link fw-bolder"
                                        [href]="'https://authentication.cargosprintgroup.com/samlLogin' | sanitizer: 'resourceUrl'"
                                        >Sign in here</a
                                    >
                                </div>
                                <div class="text-center d-block d-sm-none font-small">
                                    <a
                                        id="terms-of-use-link"
                                        class="extra__terms extra__terms--link fw-bolder"
                                        target="_blank"
                                        [href]="'https://cargosprint.com/legal/terms-of-use/sprintpay' | sanitizer: 'resourceUrl'"
                                        >Terms of Use</a
                                    ><span class="extra__terms">&nbsp;&nbsp;&&nbsp;&nbsp;</span
                                    ><a
                                        id="privacy-policy-link"
                                        class="extra__terms extra__terms--link fw-bolder"
                                        target="_blank"
                                        [href]="'https://cargosprint.com/legal/privacy-policy' | sanitizer: 'resourceUrl'"
                                        >Privacy Policy</a
                                    >
                                </div>
                            </div>
                        </div>
                        <!--END: Extras -->
                    </div>

                    <div class="d-none d-md-flex justify-content-between w-100 p-2 p-lg-3">
                        <div class="text-center font-small">
                            <a
                                class="extra__terms extra__terms--link fw-bolder"
                                target="_blank"
                                [href]="'https://cargosprint.com/legal/terms-of-use/sprintpay' | sanitizer: 'resourceUrl'"
                                >Terms of Use</a
                            ><span class="extra__terms">&nbsp;&nbsp;&&nbsp;&nbsp;</span
                            ><a
                                class="extra__terms extra__terms--link fw-bolder"
                                target="_blank"
                                [href]="'https://cargosprint.com/legal/privacy-policy' | sanitizer: 'resourceUrl'"
                                >Privacy Policy</a
                            >
                        </div>
                        <!--START: Brand logo -->
                        <div class="text-center my-auto">
                            <img src="./assets/images/powered-by-cargosprint.svg" alt="Powered by CargoSprint" />
                        </div>
                        <!--END: Brand logo -->
                    </div>
                    <!--START: Brand logo -->
                    <div class="text-center d-block d-sm-none">
                        <img src="./assets/images/powered-by-cargosprint.svg" alt="Powered by CargoSprint" />
                    </div>
                    <!--END: Brand logo -->
                </div>
            </div>
            <!--END: Right container -->
        </div>
    </div>
</section>
<!--END: Login -->
<!--START: NGX SPINNER -->
<app-loader></app-loader>
<!--END: NGX SPINNER -->

<ng-template #authenticationFlux>
    <app-authentication-flux
        messageAuthentication="This is an extra layer of security that helps protect your account by requiring a form of verification before granting access."
        module="REGISTRATION"
    ></app-authentication-flux>
</ng-template>
