import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ErrorInputFieldComponent } from '@cargos/sprintpay-models';
import { PhoneInputComponent } from '@cargos/sprintpay-ui';
import { Subject, takeUntil } from 'rxjs';
import { PersonalInformationFormGroupType } from '../../models/form-types';

@Component({
    selector: 'app-personal-information',
    templateUrl: './personal-information.component.html',
    styleUrl: './personal-information.component.scss',
})
export class PersonalInformationComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() cancelEvent: EventEmitter<boolean> = new EventEmitter();
    @ViewChild(PhoneInputComponent) phoneInputComponent: PhoneInputComponent;
    public parentForm: PersonalInformationFormGroupType;
    public errorList: ErrorInputFieldComponent[];
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        this.parentForm = this.controlContainer.control as PersonalInformationFormGroupType;

        this.errorList = [
            {
                code: 'required',
                message: `Required field`,
            },
            {
                code: 'pattern',
                message: `Oops. The email address was malformed! Please try again`,
            },
        ];
    }

    ngAfterViewInit(): void {
        this.parentForm.controls['phoneForm'].controls['code'].setValue(
            this.phoneInputComponent?.phoneForm.controls['code'].value
        );

        this.phoneInputComponent?.phoneForm
            .get('code')
            ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((code) => {
                this.parentForm.controls['phoneForm'].controls['code'].setValue(code);
            });

        this.phoneInputComponent?.phoneForm
            .get('phone')
            ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((code) => {
                this.parentForm.controls['phoneForm'].controls['phone'].setValue(code);
            });
    }

    cancel(): void {
        this.cancelEvent.emit(true);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
