import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Charge, CompletedPayment, Exception, Facility, FlightDetail, ShipmentInfo } from '@cargos/sprintpay-models';
import { AwbService, StorageService, StorageTypes } from '@cargos/sprintpay-services';
import { CustomValidators } from '@cargos/sprintpay-utils';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, from, map, of, switchMap, throwError } from 'rxjs';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { SearchException } from 'src/app/shared/models/search-exceptions';
import { SearchExceptionType } from 'src/app/shared/models/search-exceptions-const';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InvalidAWBList } from '../utils/invalid-awb-list-const';
import { SummaryService } from './summary/summary.service';
export interface FlightData {
    flightNumber: string;
    flightDate: string;
}
export interface AwbLookupResult {
    shipmentInfo: ShipmentInfo;
    dynamicFields: any;
}
export interface facilityLookupQueryField {
    pickupDate: string;
    aWBPrefix: string;
    aWBNumber: string;
}
export interface ExternalCharge {
    charges: Charge[];
    extraItems: ExtraItem[];
}

export interface ExtraItem {
    id: number;
    name: string;
    paymentType: string;
    validPrefixes: string;
    destinations: string;
    orderId: number;
    isFreeForm: boolean;
    minimum: number;
    isMinimumPerDay: boolean;
    isAccessorial: boolean;
    isStorage: boolean;
    rate: number;
    isPrimary: boolean;
    unit: string;
    freeStorageHours: number;
}
@Injectable({
    providedIn: 'root',
})
export class AwbLookUpService {
    private _current_payment: BehaviorSubject<ShipmentInfo | null> = new BehaviorSubject<ShipmentInfo | null>(null);
    private _awbs: BehaviorSubject<Charge[] | null> = new BehaviorSubject<Charge[] | null>(null);
    private pickupDateFormat = 'YYYY-MM-DD';
    private pickupDateAndTimeFormat = 'YYYY-MM-DD HH:mm';
    private _facilitiesUrl: string;
    constructor(
        private awbService: AwbService,
        private storageService: StorageService,
        private summaryService: SummaryService,
        private router: Router,
        private paymentFluxService: PaymentFluxService,
        private _tokenService: TokenService,
        private _httpClient: HttpClient
    ) {
        this._facilitiesUrl = environment.uris.method.facilities;
        const shipment_info: ShipmentInfo | null = this.storageService.getElement(
            'shipment_info',
            StorageTypes.SESSION
        );
        if (shipment_info) {
            this.setShipmentInfo(shipment_info);
        }
    }

    get instant_current_payment(): ShipmentInfo | null {
        return this._current_payment.value;
    }

    getCurrentPayment$(): Observable<ShipmentInfo | null> {
        return this._current_payment.asObservable();
    }

    private setShipmentInfo(shipmentInfo: ShipmentInfo | null): void {
        if (shipmentInfo) {
            this.summaryService.setCostCenter(shipmentInfo.costCenter || '');
            this.summaryService.setSplitShipmentStatus(shipmentInfo.isSplit || false);
        }
        this._current_payment.next(shipmentInfo);
    }

    private saveShipmentInfo(shipmentInfo: ShipmentInfo | null): void {
        this.storageService.saveElement('shipment_info', shipmentInfo, StorageTypes.SESSION);
    }

    setAndSaveOnStorageShipmentInfo(shipmentInfo: ShipmentInfo | null): void {
        this.setShipmentInfo(shipmentInfo);
        this.saveShipmentInfo(shipmentInfo);
    }

    searchAWBLookup(
        facilityId: number | undefined,
        awb: string | null,
        queryFieldValues: facilityLookupQueryField,
        operation?: string,
        date = dayjs().format(this.pickupDateAndTimeFormat)
    ): Observable<ShipmentInfo | null> {
        let queryFields = '',
            queryValues = '';
        let queryFieldKeys = Object.keys(queryFieldValues);
        queryFieldKeys.forEach((key: string, index: number) => {
            queryFields += key.trim() + (queryFieldKeys.length - 1 == index ? '' : ',');
            queryValues += queryFieldValues[key].trim() + (queryFieldKeys.length - 1 == index ? '' : ',');
        });
        return this.getPaymentInfo(facilityId, awb, date, queryFields, queryValues, operation);
    }
    facilityLookup(facilityId, queryFields, queryValues, operation): Observable<any> {
        const queryParams = new URLSearchParams({
            operation,
            queryFields,
            queryValues,
        });

        const url = new URL(`${this._facilitiesUrl}/facility/${facilityId}/lookup?${queryParams}`);
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<Facility>(url.toString(), { headers });
    }
    getPaymentInfo(facilityId, awb, date, queryFields, queryValues, operation): Observable<ShipmentInfo> {
        const currentPayment = this.paymentFluxService.getCurrentPayment();
        const facility = currentPayment?.facility;
        // const facility = this.currentFacilityService.getFacilitySource();

        return this.facilityLookup(facilityId, queryFields, queryValues, operation).pipe(
            switchMap((awbLookupResult: any) => {
                const paymentRecord: ShipmentInfo = awbLookupResult.shipmentInfo;
                if (paymentRecord) {
                    return of(paymentRecord);
                }

                return this.isAllowedContinueWithAWBNotFound().pipe(map(() => paymentRecord));
            }),
            map((paymentRecord: ShipmentInfo) => {
                paymentRecord.charges.forEach((charge: Charge, index: number) => {
                    charge.uniqueId = index.toString();
                });
                paymentRecord.awb = awb;
                paymentRecord.date = date;
                this.summaryService.setMAWB(awb);
                // TODO: Remov awb and date
                this.setAndSaveOnStorageShipmentInfo(paymentRecord);
                return paymentRecord;
            })
        );
    }
    getAwbsCharges$(): Observable<Charge[]> {
        return this.getCurrentPayment$().pipe(
            map((shipmentInfo) => {
                if (!shipmentInfo?.charges?.length) {
                    return [];
                }
                return shipmentInfo.charges;
                // return this.filterChargesByAwbAndOrder(shipmentInfo.charges);
            })
        );
    }

    getHawbsCharges$(): Observable<Charge[]> {
        return this.getCurrentPayment$().pipe(
            map((shipmentInfo) => {
                if (!shipmentInfo?.charges?.length) {
                    return [];
                }

                return this.filterChargesByHawbAndOrder(shipmentInfo.charges);
            })
        );
    }

    awbContainsHAWBs$(): Observable<boolean> {
        return this.getCurrentPayment$().pipe(
            map((shipmentInfo) => {
                if (!shipmentInfo?.charges?.length) {
                    return false;
                }

                return !!this.filterChargesByHawbAndOrder(shipmentInfo.charges).length;
            })
        );
    }

    public get instant_awbs_charges(): Charge[] | null {
        return this._awbs.value;
    }

    filterChargesByAwbAndOrder(charges: Charge[]): Charge[] {
        return charges.filter((charge) => !charge.hasOwnProperty('hawbId')).sort((a, b) => a.orderId - b.orderId);
    }

    filterChargesByHawbAndOrder(charges: Charge[]): Charge[] {
        return charges
            .filter((charge) => charge.hasOwnProperty('hawbId') && charge.hawbId !== null)
            .sort((a, b) => a.orderId - b.orderId);
    }

    public get instant_hawbs_charges(): Charge[] {
        return this.filterChargesByHawbAndOrder(this.instant_current_payment?.charges || []);
    }

    getCompletedStorageAwbCompletsByFlight(flightNumber?: string, flightDate?: string): CompletedPayment[] {
        if (!this.instant_current_payment?.completedPayments?.length) {
            return [];
        }

        return this.instant_current_payment?.completedPayments?.filter(
            (paymentcompleted) =>
                !paymentcompleted.hasOwnProperty('hawb') &&
                paymentcompleted.paymentType?.toLocaleLowerCase().includes('storage') &&
                (paymentcompleted.externalData?.flightNumber || '') === (flightNumber || '') &&
                (paymentcompleted.externalData?.flightDate
                    ? dayjs(paymentcompleted.externalData?.flightDate).format('YYYY-MM-DD')
                    : '') === (flightDate ? dayjs(flightDate).format('YYYY-MM-DD') : '')
        );
    }

    getCompletedStorageHawbPaymentsByHawbId(
        hawbId: string,
        flightNumber?: string,
        flightDate?: string
    ): CompletedPayment[] {
        if (!this.instant_current_payment?.completedPayments?.length) {
            return [];
        }

        return this.instant_current_payment?.completedPayments.filter(
            (paymentcompleted) =>
                paymentcompleted.hasOwnProperty('hawb') &&
                paymentcompleted.hawb !== null &&
                paymentcompleted.paymentType?.includes('storage') &&
                paymentcompleted.hawb === hawbId &&
                (paymentcompleted.externalData?.flightNumber || '') === (flightNumber || '') &&
                (paymentcompleted.externalData?.flightDate
                    ? dayjs(paymentcompleted.externalData?.flightDate).format('YYYY-MM-DD')
                    : '') === (flightDate ? dayjs(flightDate).format('YYYY-MM-DD') : '')
        );
    }

    isAllowedToSearch(awb: string): Observable<boolean> {
        return of(CustomValidators.isValidAWB(awb) || InvalidAWBList.includes(awb)).pipe(
            switchMap((isValidAWB) => {
                if (isValidAWB) {
                    return of(true);
                }

                return this.isAllowedSearchInvalidAWBModal();
            }),
            switchMap((allowContinue) => {
                // if (allowContinue) {
                //     return this.isAllowedMultipleAWBs();
                // }

                return of(allowContinue);
            })
        );
    }

    isAllowedMultipleAWBs(): Observable<boolean> {
        return from(
            Swal.fire({
                title: 'You already have payment in your cart',
                text: `Do you want to start again?`,
                icon: 'warning',
                cancelButtonText: 'New search',
                confirmButtonText: 'Go to cart',
                showCancelButton: true,
                showConfirmButton: true,
                showCloseButton: true,
                customClass: {
                    cancelButton: 'order-1',
                    confirmButton: 'order-2',
                },
            })
        ).pipe(
            switchMap((result) => {
                // if (result.dismiss === Swal.DismissReason.cancel) {
                //     return this.cartBillService.deleteCart(false).pipe(map(() => true));
                // }

                if (result.isConfirmed) {
                    this.router.navigate(['/cart']);
                }

                return of(false);
            })
        );

        return of(true);
    }

    isAllowedSearchInvalidAWBModal(): Observable<boolean> {
        return from(
            Swal.fire({
                title: 'Oops!',
                text: 'The AWB entered is not a valid AWB number, please confirm to continue.',
                icon: 'warning',
                allowOutsideClick: false,
                showCancelButton: true,
                customClass: {
                    cancelButton: 'btn link',
                },
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                showCloseButton: false,
                reverseButtons: true,
                allowEscapeKey: false,
            })
        ).pipe(map((result) => result.isConfirmed));
        return throwError(() => SearchException.fromJson({ code: SearchExceptionType.INVALID_AWB }));
    }

    isAllowedContinueWithAWBNotFound(): Observable<boolean> {
        return throwError(() =>
            Exception.fromJson({
                description:
                    'We could not find the AWB you entered. Please contact your airport representative for further details. AWBs will only show up after they have been manifested and departed from port of loading.',
                type: 'warning',
            })
        );
        return of(true);
    }

    findNotificationDetail(status: string): FlightDetail | undefined {
        return this.instant_current_payment?.flightDetails?.find((flight) => {
            return flight?.notificationDetails.find((notification) => notification.statusCode === status);
        });
    }

    isAWBReceivedFromFlight(): boolean {
        return !!this.findNotificationDetail('RCF');
    }

    isAWBReceivedFromShipper(): boolean {
        return !!this.findNotificationDetail('RCS');
    }

    isAWBDelivered(): boolean {
        return !!this.findNotificationDetail('DLV');
    }

    isAWBAgentNotifiedOfArrival(): boolean {
        return !!this.findNotificationDetail('NFD');
    }

    getFlightNumbersAndFlightDatesOnFlightDetails(): FlightData[] {
        return (
            this.instant_current_payment?.flightDetails
                .map((flight) => {
                    return {
                        flightNumber: flight?.flightNumber,
                        flightDate: flight?.flightDate,
                    };
                })
                .sort((a, b) => this.sortByDate(a.flightDate, b.flightDate)) || []
        );
    }

    private sortByDate(date: string, dateTwo: string): 1 | -1 | 0 {
        const a = new Date(date).getTime();
        const b = new Date(dateTwo).getTime();

        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    }

    getAwbsChargesByFlightNumberAndFlightDate$(flightNumber: string, flightDate: string): Observable<Charge[]> {
        return this.getAwbsCharges$().pipe(
            map((awbsCharges: Charge[]) => {
                return awbsCharges.filter(
                    (charge: Charge) =>
                        charge.flightNumber === flightNumber &&
                        (charge.flightDate ? dayjs(charge.flightDate).format('YYYY-MM-DD') : '') ===
                            (flightDate ? dayjs(flightDate).format('YYYY-MM-DD') : '')
                );
            })
        );
    }

    getHawbsChargesByFlightNumber$(flightNumber: string, flightDate: string): Observable<Charge[]> {
        return this.getHawbsCharges$().pipe(
            map((awbsCharges: Charge[]) => {
                return awbsCharges.filter(
                    (charge: Charge) =>
                        charge.flightNumber === flightNumber &&
                        (charge.flightDate ? dayjs(charge.flightDate).format('YYYY-MM-DD') : '') ===
                            (flightDate ? dayjs(flightDate).format('YYYY-MM-DD') : '')
                );
            })
        );
    }

    getCharges$(): Observable<Charge[]> {
        return this.getCurrentPayment$().pipe(
            map((shipmentInfo: ShipmentInfo | null) => {
                if (!shipmentInfo?.charges?.length) {
                    return [];
                }

                return shipmentInfo.charges;
            })
        );
    }

    getChargeByUniqueId(uniqueId: string): Observable<Charge | undefined> {
        return this.getCharges$().pipe(map((charges) => charges.find((charge) => charge.uniqueId === uniqueId)));
    }

    getFlights$(): Observable<FlightDetail[]> {
        return this.getCurrentPayment$().pipe(
            map((shipmentInfo) => {
                return shipmentInfo?.flightDetails || [];
            })
        );
    }

    getFlightByFlightNumber$(flightNumber: string): Observable<FlightDetail | undefined> {
        return this.getFlights$().pipe(
            map((flights) => {
                return flights.find((flight) => {
                    return flight.flightNumber === flightNumber;
                });
            })
        );
    }

    getFlightByFlightNumberAndFlightDate$(
        flightNumber: string,
        flightDate: string
    ): Observable<FlightDetail | undefined> {
        return this.getFlights$().pipe(
            map((flights) => {
                return flights.find((flight) => {
                    return (
                        (flight.flightNumber || '') === (flightNumber || '') &&
                        (flight.flightDate ? dayjs(flight.flightDate).format('YYYY-MM-DD') : '') ===
                            (flightDate ? dayjs(flightDate).format('YYYY-MM-DD') : '')
                    );
                });
            })
        );
    }

    getAwbsFromShipmentInfo(): string | undefined {
        return this.instant_current_payment?.awb;
    }

    updateCharges(charges: Charge[]): void {
        const shipment_info = this.instant_current_payment;
        if (shipment_info) {
            shipment_info.charges = charges;
        }
        this._current_payment.next(shipment_info);
    }
}
