import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Exception, Facility, ShipmentInfo } from '@cargos/sprintpay-models';
import { CustomMasks, CustomValidators } from '@cargos/sprintpay-utils';
import dayjs from 'dayjs';
import { map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { DynamicDialogService } from 'src/app/shared/components/dynamic-dialog/services/dynamic-dialog.service';
import { PaymentStatusType } from 'src/app/shared/models/payment-status';
import { SearchType } from 'src/app/shared/models/search-type';
import { AwbLookUpService, facilityLookupQueryField, SummaryService } from 'src/app/shared/services';
import Swal from 'sweetalert2';
import { AWBSearchFormErrorJSON, AWBSearchFormGroupType } from './models/awb-search-form';
import { prefixesWithOutSubscription } from './models/const';
import { FacilityLocationsFormGroupType } from './models/location-form';

@Component({
    selector: 'app-awb-search',
    templateUrl: './awb-search.component.html',
    styleUrl: './awb-search.component.scss',
})
export class AwbSearchComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void>;
    private previousAWBCompletedValue = false;
    private isAWBCompleted = false;
    private mobileQueryListener: () => void;
    public mobileQuery: MediaQueryList;
    public isCompleted = false;
    public isLoading = false;
    public searchForm: AWBSearchFormGroupType;
    public maskAwb = CustomMasks.iMaskAwb;
    public formError: AWBSearchFormErrorJSON;
    public showStation = false;
    public showSearchButtonOutside: boolean;

    @Input() disabled = false;
    @Output() eventOnEnter = new EventEmitter<AWBSearchFormGroupType>();
    @Output() eventOnChangeLocation = new EventEmitter<FacilityLocationsFormGroupType>();
    @Output() eventOnSearching = new EventEmitter<boolean>();
    @Input() facility: Facility | undefined;

    constructor(
        private fb: NonNullableFormBuilder,
        private awbLookUpService: AwbLookUpService,
        private summaryService: SummaryService,
        private router: Router,
        private media: MediaMatcher,
        private changeDetectorRef: ChangeDetectorRef,
        private matDialog: MatDialog,
        private dynamicDialogService: DynamicDialogService,
        private paymentFluxService: PaymentFluxService,
        private errorHandlerService: ErrorHandlerService
    ) {
        this.unsubscribe$ = new Subject<void>();

        this.mobileQuery = this.media.matchMedia('(max-width: 767px)');
        this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    }

    get mawb(): AbstractControl<string> | null {
        return this.searchForm.get('mawb');
    }

    ngOnInit(): void {
        this.createForm();
        this.setFormError();
        this.subscribeFormChanges();
        this.subscribeMobileSize();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

        this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    }

    submit(form: AWBSearchFormGroupType): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        this.eventOnSearching.emit(true);
        // setTimeout(() => {
        //     this.redirectTo('ORD');
        // }, 2000);
        const currentPayment = this.paymentFluxService.getCurrentPayment();
        this.searchAWB(form)
            .pipe(
                take(1),
                switchMap((payment: ShipmentInfo | null) => {
                    if (!payment) {
                        this.isLoading = false;
                        return of(null);
                    }
                    return this.isDestinationNeeded(payment).pipe(
                        map((isDestinationNeeded) => {
                            return { payment, isDestinationNeeded };
                        })
                    );
                })
            )
            .subscribe({
                next: (
                    response: {
                        payment: ShipmentInfo;
                        isDestinationNeeded: boolean;
                    } | null
                ) => {
                    if (response?.payment) {
                        const { payment, isDestinationNeeded } = response;
                        this.isLoading = false;
                        this.summaryService.newCurrentPaymentStatusEvent(PaymentStatusType.PAYMENT_INCOMPLETE);
                        this.summaryService.setAndSaveSearchTypeInStorage(SearchType.awb);
                        this.eventOnSearching.emit(false);

                        if (isDestinationNeeded) {
                            this.showStation = true;
                        }
                        // if (payment.isCancelAutoPayEnable === false) {
                        //     this.displaySubscriptionModal(
                        //         form.value?.mawb!,
                        //         this.awbSubscriptionService.isSubscriptionAvailable(),
                        //         'subscription'
                        //     );
                        //     return;
                        // }
                        // if (payment.isAutoPaySubscriptionExist) {
                        //     this.displayCancelAutoPay(this.mawb!.value);
                        //     return;
                        // }

                        // this.awbSubscriptionService.setIsArrivalSubscriptionExist(payment.isArrivalSubscriptionExist!);

                        if (payment.isError) {
                            if (form.value?.mawb) {
                                // this.displaySubscriptionModal(form.value.mawb, false);
                            }
                            return;
                        }
                        // if (this.awbSubscriptionService.isSubscriptionAvailable()) {
                        //     if (this.mawb?.value) {
                        //         this.displaySubscriptionModal(this.mawb.value, true);
                        //     }
                        //     return;
                        // }
                        // this.awbSubscriptionService.setIsEnabledSubscriptionWithoutRCForNFD(false);
                        if (payment && currentPayment) {
                            // this.redirectTo(currentPayment.facility || '');
                        }
                    }
                },
                error: (error: Exception) => {
                    this.eventOnSearching.emit(false);
                    this.isLoading = false;
                    const err = this.errorHandlerService.errorMsg(error);

                    Swal.fire({
                        title: error.title,
                        text: error.description || err,
                        icon: error.type,
                    });
                },
            });
    }
    isDestinationNeeded(payment: ShipmentInfo): Observable<boolean> {
        return of(!payment.shipmentDetails?.destination);
    }
    isNeccesarySubscription(payment: ShipmentInfo): boolean {
        if (!payment?.charges?.length) {
            return true;
        }

        return !(
            this.awbLookUpService.isAWBReceivedFromFlight() ||
            this.awbLookUpService.isAWBAgentNotifiedOfArrival() ||
            this.awbLookUpService.isAWBReceivedFromShipper()
        );
    }

    searchAWB(form: AWBSearchFormGroupType): Observable<ShipmentInfo | null> {
        if (form.valid) {
            return this.searchMAWB(form);
        }

        return of(null);
    }

    searchMAWB(form: AWBSearchFormGroupType): Observable<ShipmentInfo | null> {
        this.emitEventForm(form);
        const mawb = form.controls.mawb.value.split('-');
        const queryFields: facilityLookupQueryField = {
            pickupDate: dayjs().format('YYYY-MM-DD HH:mm'),
            aWBPrefix: mawb[0],
            aWBNumber: mawb[1],
        };
        return this.awbLookUpService.searchAWBLookup(this.facility?.id, form.controls.mawb.value, queryFields, '');
    }

    emitEventForm(form: AWBSearchFormGroupType): void {
        this.eventOnEnter.emit(form);

        if (this.isAWBCompleted) {
            this.isCompleted = true;
        }
    }

    redirectTo(facility: Facility): void {
        this.router.navigate([`admin/facilityPayments/newPayment/facility/${facility.id}/payment/details`], {
            queryParams: {
                mawb: 'mawb',
                location,
            },
        });
    }

    onAWBStatusChange(): void {
        this.showStation = false;
    }

    subscribeFormChanges(): void {
        this.mawb?.valueChanges
            .pipe(
                map((values) => {
                    const lengthAWB = values?.replace(/_/g, '').length;

                    return lengthAWB === 12;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((isAWBCompleted) => {
                if (!isAWBCompleted) {
                    this.isCompleted = false;
                }

                this.isAWBCompleted = isAWBCompleted;

                if (this.previousAWBCompletedValue !== this.isAWBCompleted) {
                    this.previousAWBCompletedValue = this.isAWBCompleted;
                    this.onAWBStatusChange();
                }
            });
    }

    createForm(): void {
        this.searchForm = this.fb.group({
            mawb: ['', [Validators.required], [CustomValidators.awbValidator()]],
        });
    }

    setFormError(): void {
        this.formError = {
            mawb: [
                {
                    code: 'required',
                    message: 'Required field',
                },
                {
                    code: 'invalid',
                    message: 'The AWB is invalid, please review the format',
                },
            ],
        };
    }

    cleanInput(): void {
        this.searchForm.reset();
        this.mawb?.setErrors(null);
    }

    onSelectShipmentLocation(form: FacilityLocationsFormGroupType): void {
        this.eventOnChangeLocation.emit(form);
    }

    /**
     * TODO: Remove this logic, it is temporal.
     * If it is not temporal, please move this logic to the config file
     * */
    public prefixesWithOutSubscription = prefixesWithOutSubscription;

    validateSubscriptionAvailable(): boolean {
        return this.prefixesWithOutSubscription.some((prefix) => {
            return this.mawb?.value?.includes(prefix);
        });
    }
    /** End of logic to remove */

    subscribeMobileSize(): void {
        this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    }
}
