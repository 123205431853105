import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DynamicDialogComponent } from '../dynamic-dialog.component';
import { DynamicDialogData } from '../models/dynamic-dialog-data';

@Injectable({
    providedIn: 'root',
})
export class DynamicDialogService {
    private initialConfig: MatDialogConfig = {
        maxWidth: '-webkit-fill-available',
    };

    constructor(private dialog: MatDialog) {}

    showDialog<T, D = unknown, R = unknown>(data: DynamicDialogData<T, D>): MatDialogRef<DynamicDialogComponent, R> {
        const customConfig: MatDialogConfig<D> = {
            ...this.initialConfig,
            ...data.config,
        };

        return this.dialog.open(DynamicDialogComponent, {
            ...customConfig,
            data,
        });
    }

    closeDialog(): void {
        this.dialog.closeAll();
    }
}
