<app-file-upload
    [whiteListFile]="filesAccepted"
    [subtitle]="fieldData.subLabel || ''"
    [title]="fieldData.label || ''"
    (filesAdded)="filesAdded($event)"
    [loading]="uploading"
    [tooltipMessage]="fieldData.tooltipMessage || ''"
    [isDisabled]="fieldData.isDisabled || false"
></app-file-upload>
<mat-error>
    <app-dynamic-error [field]="fieldData"></app-dynamic-error>
</mat-error>
