<div class="mat-subtitle-1">{{title}}</div>
<div class="mat-subtitle-1">{{subtitle}}</div>
<div
    ngfDrop
    class="file-upload-body drop-zone-area"
    [selectable]="selectable"
    [multiple]="multipleFiles"
    [(files)]="files"
    (filesChange)="filesChange()"
    title="Add documentation. Drop/select a document"
    [matTooltip]="tooltipMessage"
    [fileDropDisabled]="isDisabled"
>
    <span *ngIf="!validating">
        <div class="col-12 d-block text-center">
            <p><i class="bi bi-cloud-arrow-up me-2"></i>Drag and drop or upload your file</p>
        </div>
        <div class="col-12 my-2 d-flex justify-content-center">
            <div class="upload-button d-flex justify-content-center align-items-center text-uppercase">
                UPLOAD FILE
                <input
                    #fileInput
                    type="file"
                    multiple="multiple"
                    accept=".docx,.doc,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, image/*"
                />
            </div>
        </div>
        <div class="col-12 my-2 d-flex justify-content-center">
            <div class="d-flex flex-column flex-md-row">
                <div class="mt-2">
                    <app-file-remover></app-file-remover>
                </div>
            </div>
        </div>
    </span>
    <div *ngIf="validating || loading" class="d-flex align-items-center justify-content-center">
        <mat-spinner diameter="24"></mat-spinner>
    </div>
</div>
