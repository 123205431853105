import { Injectable } from '@angular/core';
import { PaymentCart } from '@cargos/sprintpay-models';
import { companyName } from '../utils/constants';

@Injectable({
    providedIn: 'root',
})
export class PaymentToRequestEditService {
    private payment: any;

    constructor() {
        this.payment = {};
    }

    /**
     * @method getPaymentRequestDataCart()
     * @param (paymentRequest: any)
     * @param (route? : string)
     * @description Creates the necessary data for the payment request process
     */

    getPaymentRequestDataCart(paymentRequest: PaymentCart, route?: string, companyType?: string) {
        this.payment = {};
        this._setPaymentDataCompanyReference(paymentRequest, companyType);
        this._setPaymentDataFacility(paymentRequest);
        this._setPaymentDataDetails(paymentRequest);
        this._setPaymentDataReference(paymentRequest);
        this._setPaymentDataId(paymentRequest);
        route ? this._setPaymentDataRedirectRoute(route) : null;
        this._setPaymentDataPaymentSelected(paymentRequest);
        if (paymentRequest.dynamicValues) {
            this.payment.dynamicValues = paymentRequest.dynamicValues;
            this.payment.source = paymentRequest?.externalData?.source || '';
        }
        return this.payment;
    }

    /**
     * @method _setPaymentDataFacility()
     * @param (paymentRequest: any)
     * @description Set the data from the payment into the needed data to be structured for the payment request process   */

    private _setPaymentDataFacility(paymentRequest: any) {
        if (paymentRequest.facility && paymentRequest.facility.notesForClients) {
            paymentRequest.facility.notesForClients = JSON.stringify(paymentRequest.facility.notesForClients);
        }
        this.payment['facility'] = paymentRequest.facility;
    }

    /**
     * @method _setPaymentDataDetails()
     * @param (paymentRequest: any)
     * @description Set the data from the payment into the needed data to be structured for the payment request process
     */

    private _setPaymentDataDetails(paymentRequest: any) {
        this.payment['details'] = {
            awb: paymentRequest.awb,
            amount: paymentRequest.amount,
            hawb: paymentRequest.hawb,
            nameFiles: paymentRequest.attachments,
            paymentType: paymentRequest.paymentType,
            prefix: paymentRequest.awb ? paymentRequest.awb.substring(0, 3) : '',
            number: paymentRequest.awb ? paymentRequest.awb.substring(4, 12) : '',
            paidTo: paymentRequest.paidTo,
            eiReferenceFileNumber: paymentRequest.eiReferenceFileNumber
                ? paymentRequest.eiReferenceFileNumber
                : paymentRequest.externalData
                  ? paymentRequest.externalData.eiReferenceFileNumber
                  : null,
            pickupDate: paymentRequest.externalData?.pickupDate || null,
            customerReference: paymentRequest.customerReference || null,
            notificationEmail: paymentRequest.notificationEmail || null,
        };
    }

    /**
     * @method _setPaymentDataCompanyReference()
     * @param (paymentRequest: any)
     * @param (currentCompanyName: string)
     * @description Set the data from the payment into the needed data to be structured for the payment request process
     */

    private _setPaymentDataCompanyReference(paymentRequest: any, currentCompanyName?: string) {
        if (currentCompanyName) {
            switch (currentCompanyName) {
                case companyName.emotrans:
                    this.payment.companyCustomerReference = {
                        paymentAmount: paymentRequest.paymentAmount
                            ? paymentRequest.paymentAmount
                            : paymentRequest.externalData?.type,
                        type: paymentRequest.paymentType ? paymentRequest.paymentType : paymentRequest.type,
                        locationId: paymentRequest.location?.id,
                        locationName: paymentRequest.location?.name,
                    };
                    break;
                case companyName.geodis:
                    this.payment.companyCustomerReference = {
                        paymentAmount: paymentRequest.paymentAmount
                            ? paymentRequest.paymentAmount
                            : paymentRequest.externalData?.type,
                        type: paymentRequest.paymentType ? paymentRequest.paymentType : paymentRequest.type,
                        locationId: paymentRequest.location?.id,
                        locationName: paymentRequest.location?.name,
                    };
                    break;
                case companyName.kn:
                    this.payment.companyCustomerReference = {
                        paymentAmount: paymentRequest.paymentAmount
                            ? paymentRequest.paymentAmount
                            : paymentRequest.externalData?.type,
                        type: paymentRequest.type ? paymentRequest.type : paymentRequest.paymentType,
                        locationId: paymentRequest.location?.id,
                        locationName: paymentRequest.location?.name,
                    };
                    break;
                case companyName.schenker:
                    this.payment.companyCustomerReference = {
                        costCenter: paymentRequest.costCenter
                            ? paymentRequest.costCenter
                            : paymentRequest.externalData?.costCenter,
                        voucher: paymentRequest.voucher ? paymentRequest.voucher : paymentRequest.externalData?.voucher,
                        paymentAmount: paymentRequest.paymentAmount
                            ? paymentRequest.paymentAmount
                            : paymentRequest.externalData?.type,
                        type: paymentRequest.type ? paymentRequest.type : paymentRequest.paymentType,
                        locationId: paymentRequest.location?.id,
                        locationName: paymentRequest.location?.name,
                    };
                    break;
                case companyName.dhl:
                    this.payment.companyCustomerReference = {
                        sourceSystem: paymentRequest.sourceSystem,
                        product: paymentRequest.product,
                        station: paymentRequest.station,
                        type: paymentRequest.type,
                        locationId: paymentRequest.location?.id,
                        locationName: paymentRequest.location?.name,
                    };
                    break;

                case companyName.rhenus:
                    this.payment.companyCustomerReference = {
                        paymentAmount: paymentRequest.paymentAmount
                            ? paymentRequest.paymentAmount
                            : paymentRequest.externalData?.type,
                        type: paymentRequest.type ? paymentRequest.type : paymentRequest.paymentType,
                        locationId: paymentRequest.location?.id,
                        locationName: paymentRequest.location?.name,
                    };
                    break;
                default:
                    this.payment.companyCustomerReference = {
                        paymentAmount: paymentRequest.paymentAmount
                            ? paymentRequest.paymentAmount
                            : paymentRequest.externalData?.type,
                        type: paymentRequest.type ? paymentRequest.type : paymentRequest.paymentType,
                        locationId: paymentRequest.location?.id,
                        locationName: paymentRequest.location?.name,
                    };
            }
        }
    }

    /**
     * @method _setPaymentDataReference()
     * @param (paymentRequest: any)
     * @description Set the data from the payment into the needed data to be structured for the payment request process   */

    private _setPaymentDataReference(paymentRequest: any) {
        this.payment['reference'] = {
            notes: paymentRequest.notes ? paymentRequest.notes : null,
            email: paymentRequest.notificationEmail,
            customerRef: paymentRequest.customerReference
                ? paymentRequest.customerReference
                : paymentRequest.customerRef,
            externalReferenceNum: paymentRequest.externalReferenceNum
                ? paymentRequest.externalReferenceNum
                : paymentRequest.externalData
                  ? paymentRequest.externalData.externalReferenceNum
                  : null,
            glCode: paymentRequest.externalData?.glCode || null,
        };
    }

    /**
     * @method _setPaymentDataId()
     * @param (paymentRequest: any)
     * @description Set the data from the payment into the needed data to be structured for the payment request process   */

    private _setPaymentDataId(paymentRequest: any) {
        this.payment.id = paymentRequest.id;
    }

    /**
     * @method _setPaymentDataRedirectRoute()
     * @param (route: string)
     * @description Set the data from the payment into the needed data to be structured for the payment request process   */

    private _setPaymentDataRedirectRoute(route: string) {
        this.payment.redirectRoute = route;
    }

    /**
     * @method _setPaymentDataPaymentSelected()
     * @param (paymentRequest: any)
     * @description Set the data from the payment into the needed data to be structured for the payment request process   */

    private _setPaymentDataPaymentSelected(paymentRequest: any) {
        if (paymentRequest.externalData) {
            this.payment.payments_selected = [
                {
                    awbId: paymentRequest.externalData.awbId,
                    chargeId: paymentRequest.externalData.chargeId,
                    hawbId: paymentRequest.externalData.hawbId,
                    id: paymentRequest.externalData.id,
                    paymentType: paymentRequest.externalData.paymentType,
                    pickUpDate: paymentRequest.externalData.pickUpDate,
                    amount: paymentRequest.amount,
                    name: paymentRequest.externalData.name,
                },
            ];
        }
    }
    /**
     * @method _setDinamicValues()
     * @description Set the data from the payment into the needed data to be structured for the payment request process   */

    private _setDinamicValues(dynamic: any, paymentRequest: any) {
        let dynamicValues: any = {};
        Object.keys(dynamic).forEach((key: any) => {
            dynamicValues[dynamic[key].dynamicField.id] = dynamic[key].value;
        });
        this.payment.dynamicValues = dynamicValues;
        this.payment.source = paymentRequest?.externalData?.source || '';
    }
}
