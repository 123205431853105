import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class GuestService {
    constructor() {}

    showPaymentLimitPopup(): Promise<SweetAlertResult> {
        return Swal.fire({
            html: `
                <div style='padding: 0 3rem;'>
                    <h4 class="my-2">Notice!</h4>
                    <div class="row">
                        <div class="col-12 mb-2">
                            <p class="fw-light text-justify"><span class="fw-bold">TOTAL $350 payment limit for Guest payments.</span> If you plan to make a purchase exceeding this amount, we'd recommend<span class="fw-bold"> creating a SprintPay account</span> and contact our support team at <a href="mailto:support@cargosprint.com">support&#64;cargosprint.com</a> to request the payment limit to be increased.</p>
                        </div>
                    </div>
                </div>
                `,
            showConfirmButton: true,
            imageUrl: './assets/images/login/guest-alert.svg',
            confirmButtonText: 'Continue',
            confirmButtonColor: '#007E59',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: true,
            width: '45em',
            customClass: {
                confirmButton: 'continue-as-guest-btn-modal',
                closeButton: 'close-guest-modal-btn',
            },
        });
    }
}
