<div class="row">
    <!-- START: DETAIL TITLE -->
    <div class="detail__sections mb-4">
        <div class="col-12">
            <div class="d-flex justify-content-between">
                <span class="my-3"><strong>DHL Customer reference</strong></span>
                @if(isVisibleEditButton){
                <button mat-button class="my-2 px-0 text-uppercase text-decoration-none" (click)="editPaymentRequest()">
                    Edit
                </button>
                }
            </div>
        </div>
    </div>
    <!-- END: DETAIL TITLE -->
    <!-- START: DETAIL ELEMENTS -->
    <div [ngClass]="paymentDetail?.voucher === 'step1' ? 'col-12 col-md-4 col-lg-2' : 'col-12 col-md-3'">
        <p class="card-subtitle me-2"><small>Source System</small></p>
        <p>{{paymentDetail?.sourceSystem || 'N/A'}}</p>
    </div>
    <div [ngClass]="paymentDetail?.voucher === 'step1' ? 'col-12 col-md-4 col-lg-2' : 'col-12 col-md-3'">
        <p class="card-subtitle me-2"><small>Product</small></p>
        <p>{{paymentDetail?.product || 'N/A'}}</p>
    </div>
    <div [ngClass]="paymentDetail?.voucher === 'step1' ? 'col-12 col-md-4 col-lg-2' : 'col-12 col-md-3'">
        <p class="card-subtitle me-2"><small>Station</small></p>
        <p>{{paymentDetail?.station || 'N/A'}}</p>
    </div>
    <div [ngClass]="paymentDetail?.voucher === 'step1' ? 'col-12 col-md-4 col-lg-2' : 'col-12 col-md-3'">
        <p class="card-subtitle me-2">
            <small>Type</small>
        </p>
        <p>{{paymentDetail?.externalDataType || 'N/A'}}</p>
    </div>
    <div
        *ngIf="paymentDetail?.voucher"
        [ngClass]="paymentDetail?.voucher === 'step1' ? 'col-12 col-md-4 col-lg-2' : 'col-12 col-md-3'"
    >
        <p class="card-subtitle me-2"><small>Voucher #</small></p>
        <p>{{paymentDetail?.voucher || 'N/A'}}</p>
    </div>
    <div [ngClass]="paymentDetail?.voucher === 'step1' ? 'col-12 col-md-4 col-lg-2' : 'col-12 col-md-3'">
        <p class="card-subtitle me-2"><small>Location</small></p>
        <p>
            {{!!paymentDetail?.location ? paymentDetail?.location :
            paymentDetail?.companyCustomerReference?.locationName ? paymentDetail?.companyCustomerReference.locationName
            : 'N/A'}}
        </p>
    </div>
    <!-- END: DETAIL ELEMENTS -->
</div>
