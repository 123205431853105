<img class="mt-5 mx-auto d-block" src="assets/images/cargosprint/cargosprint-logo.svg" alt="Cargo Sprint Logo" />
<div class="my-3 header text-center">Apply for Cargo Facility</div>

<mat-stepper linear labelPosition="bottom" #stepper>
    <mat-step [editable]="true" errorMessage="Personal information is needed" [stepControl]="personalInformationForm">
        <ng-template matStepLabel>Personal Info</ng-template>
        <ng-template matStepContent>
            <app-personal-information
                (cancelEvent)="cancel()"
                [formGroup]="personalInformationForm"
            ></app-personal-information>
        </ng-template>
    </mat-step>

    <mat-step
        [editable]="true"
        errorMessage="Business Details information is needed"
        [stepControl]="businessDetailsForm"
    >
        <ng-template matStepLabel>Business Details</ng-template>
        <ng-template matStepContent>
            <app-business-details [formGroup]="businessDetailsForm"></app-business-details>
        </ng-template>
    </mat-step>

    <mat-step [editable]="true" errorMessage="Address information is needed" [stepControl]="addressForm">
        <ng-template matStepLabel>Address</ng-template>
        <ng-template matStepContent>
            <app-address-step [formGroup]="addressForm"></app-address-step>
        </ng-template>
    </mat-step>

    <mat-step [editable]="true" errorMessage="Security information is needed" [stepControl]="securityForm">
        <ng-template matStepLabel>Security</ng-template>
        <ng-template matStepContent>
            <app-security-step
                [formGroup]="securityForm"
                [email]="personalInformationForm.controls['email'].value"
            ></app-security-step>
        </ng-template>
    </mat-step>
</mat-stepper>
