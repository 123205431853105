export enum PaymentStatusType {
    PAYMENT_SUCESS = 1,
    PAYMENT_ERROR,
    PAYMENT_INCOMPLETE,
    UNKNOWN,
}

export const FINAL_PAYMENT_STATUS: [typeof PaymentStatusType.PAYMENT_SUCESS, typeof PaymentStatusType.PAYMENT_ERROR] = [
    PaymentStatusType.PAYMENT_SUCESS,
    PaymentStatusType.PAYMENT_ERROR,
];

export type FinalPaymentStatusType = (typeof FINAL_PAYMENT_STATUS)[number];
