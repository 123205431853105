<div class="d-flex flex-column position-relative pt-4">
    <ng-container *ngIf="data.closeButton">
        <button
            mat-icon-button
            mat-dialog-close
            aria-label="Close modal"
            class="position-absolute end-0 top-0 p-0 aling-center close-button"
        >
            <mat-icon>close</mat-icon>
        </button>
    </ng-container>
    <ng-container *ngIf="data.title">
        <div mat-dialog-title class="mat-headline-6 text-center m-0">{{data.title}}</div>
    </ng-container>
    <ng-container *ngIf="!!data.dynamicComponent$">
        <div mat-dialog-content class="pt-3">
            <ng-container *ngComponentOutlet="data.dynamicComponent$; injector: customInjector"> </ng-container>
        </div>
    </ng-container>
</div>
