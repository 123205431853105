<div class="d-flex justify-content-between">
    <button
        id="step-cancel"
        type="button"
        mat-stroked-button
        color="primary"
        class="flex-fill border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--greenBorder me-4"
        mat-button
        matStepperPrevious
        (click)="customPreviousHandler ? previousHandler() : null"
    >
        {{previousText}}
    </button>
    <button
        id="step-next"
        type="button"
        color="primary"
        [disabled]="disabled"
        class="flex-fill border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green"
        mat-button
        matStepperNext
        (click)="customNextHandler ? nextHandler() : null"
    >
        {{nextText}}
    </button>
</div>
